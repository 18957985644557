.price_wrapper {
    background-color: #FFF;
    /* height: 100vh; */
}
.price_container {
    width: 100vh;
}
.price_header {
    height: 300px;
    background-color: #49BEB7;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.price_fst-less {
    font-size: 20px;
    font-weight: 400;
}
.price_title {
    font-size: 48px;
    font-weight: 900;
}
.price_alert {
    font-size: 14px;
    font-weight: 300;
}
.price_body {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px;
    gap: 20px;
    max-width: 1280px;

}
.price_card {
    flex: 0 0 300px;
    border-radius: 32px;
    background: #F8F398;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 30px 0;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}
.price_card:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }
.card_price_title {
    color: #240747;
    font-size: 20px;
    font-weight: 500;
}
.price_cost {
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    border-radius: 20px;
    background: #FF5959;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 4px 12px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .price_body {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        height: auto;
    }
    .price_wrapper {
        height: auto;
    }
    .price_card {
        width: 60%;
        flex: auto;
    }
}
@media only screen and (max-width: 480px) {
    .price_wrapper, .price_body {
        height: auto;
    }
    .price_title {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .price_fst-less {
        font-size: 14px;
    }
    .price_header {
        height: auto;
        padding: 30px 10px;
    }
}