.main_page {
    /* height: 100vh; */
    background: #FCE13A;
}
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 10vh; */
    padding: 20px 40px;
}
.link {
    color: #222831;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    margin-right: 40px;
    transition: 0.5s;
}
.link:hover {
    color: #e74b3c;
    font-weight: 500;
}
.inst {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    color: #e74b3c;
    font-weight: 800;
    font-size: 18px;
}
.inst:hover {
    color: #222831;

}
.main_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 40vh; */
}
.main_info-subtitle {
    color: #347474;
    font-size: 28px;
    font-weight: 700;
    margin: 10px 0;
}
.geo {
    display: flex;
    align-items: center;
    color: #222831;
    font-size: 20px;
    font-weight: 300;
}
.main_info-name {
    color: #222831;
    font-family: Monomaniac One;
    font-size: 96px;
    font-weight: 400;
}
.damu {
    color: #347474;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 20px;
    text-decoration: none;
    transition: 1s;
}
.damu:hover {
    text-decoration: underline;
}
.wapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 375px;
    height: 47px;
    border-radius: 12px;
    background: #FFF;
    text-decoration: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #347474;
    font-size: 24px;
    font-weight: 800;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.wapp:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.cards {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    /* height: 40vh; */
}
.card {
    width: 32%;
    min-height: 350px;
    border-radius: 40px;
    background: rgba(252, 254, 175, 0.90);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}
.card:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.card_title {
    color: #222831;
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 12px;
}
.image-container {
    width: 300px; 
    height: 170px; 
    overflow: hidden; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
}
.card_img {
    max-width: 100%; 
    max-height: 100%; 
    object-fit: cover; 
    height: 170px;
    border-radius: 20px;
    flex-shrink: 0;
}
.card_desc {
    width: 300px;
    color: #222831;
    font-size: 14px;
    font-weight: 500;
    margin: 12px 0 20px;
}
.content {
    height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .cards, .main_info, .nav, .main_page {
        height: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .cards {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .card {
        width: 60%;
    }
    .cards, .main_info, .nav, .main_page {
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .cards {
        flex-direction: column;
        align-items: center;
    }
    .card {
        width: 100%;
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 480px) {
    .cards, .main_info, .nav, .main_page {
        height: auto;
    }
    .main_page {
        background: #FCE13A;
        padding: 40px;
    }
    
    .nav {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
    
    .main_links a.link,
    .inst,
    .wapp,
    .damu,
    .geo {
        font-size: 16px;
        margin: 4px 0;
    }
    
    .main_info {
        height: auto;
        padding: 20px 0;
    }
    
    .main_info-name {
        font-size: 48px;
    }
    
    .cards {
        flex-direction: column;
        padding: 0px;
    }
    
    .card_img {
        width: 80%;
        height: auto;
    }
    .main_links {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .main_links {
        display: none;
    }
    .main_info-subtitle {
        font-size: 20px;
        margin: 0;
    }
    .wapp {
        width: 100%;
        margin: 20px 0 10px;
    }
    .damu {
        font-size: 10px;
    }
    .card_desc {
        width: 80%;
    }

  }