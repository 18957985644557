.about_header {
    background-color: #AFC5FF;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 48px;
    font-weight: 900;
}
.about_content {
    max-width: 1280px;
    /* height: 40vh; */
    margin: 0 auto;
    padding: 40px 0;
}
.about_prg {
    font-size: 18px;
}
.about_prg_title {
    font-weight: 700;
}
.about_prg_text {
    font-weight: 400;
    margin-bottom: 20px;
}
.about_photos {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 0 40px;
    display: flex;
    justify-content: space-evenly;
    /* height: 40%; */
}
.about_photos > img {
    border-radius: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 400px;
}
.about_photos > img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .about_wrapper, .about_content {
        height: auto;
    }
    .about_photos > img {
        max-height: 200px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .about_content {
        padding: 40px;
        height: auto;
    }
    .about_photos {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .about_photos > img {
        width: 60%;
    }
    .about_wrapper {
        height: auto;
    }
}
@media only screen and (max-width: 768px) {
    .about_content {
        padding: 40px;
        height: auto;
    }
    .about_photos {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .about_photos > img {
        width: 60%;
    }
    .about_wrapper {
        height: auto;
    }
}
@media only screen and (max-width: 480px) {
    .about_header {
        font-size: 30px;
        height: auto;
        padding: 30px 10px;
    }
    .about_wrapper {
        height: auto;
    }
    .about_content {
        padding: 40px 40px 15px;
        height: auto;
    }
    .about_photos {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .about_photos > img {
        width: 70%;
    }
    .about_second_img {
        display: none;
    }
}