.contact_wrapper {
    height: 100vh;
}
.contact_header {
    background: #240747;
    color: #fff;
    font-size: 48px;
    font-weight: 900;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact_body {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
    height: 80%;
    padding: 20px 0;
}
.contact_social {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}
.social_item {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}
.social_item:hover .social_item_text {
    opacity: 1;
}
.social_item_text {
    font-size: 20px;
    font-weight: 800;
    color: #240747;
    opacity: 0.7;
}
.contact_social_bl {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.contact_maps {
    display: flex;
    justify-content: space-around;
}
.contact_map {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.contact_map_adress {
    font-size: 20px;
    font-weight: 700;
}
.my-maps {
    height: 400px;
    width: 500px;
}
.contact_devider {
    margin: 20px;
    color: #240747;
    opacity: 0.7;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .contact_maps {
        flex-direction: column;
        gap: 30px;
        padding: 30px 0;
    }
}
@media only screen and (max-width: 768px){
    .contact_maps {
        flex-direction: column;
        gap: 20px;
    }
}
@media only screen and (max-width: 480px) { 
    .contact_header {
        font-size: 30px;
        height: auto;
        padding: 30px 10px;
    }
    .contact_social {
        flex-direction: column;
        align-items: self-start;
        padding: 0 40px 20px;
        gap: 20px;
    }
    .social_item_text {
        font-size: 16px;
    }
    .contact_maps {
        flex-direction: column;
        gap: 30px;
        padding: 0 0 30px;
    }
    .my-maps {
        height: 200px;
        width: 250px;
    }
    .contact_map_adress {
        font-size: 16px;
    }
    .contact_body {
        padding: 40px 40px 0;
        height: auto;
    }
}